import React, { Fragment,useState,useEffect } from "react";
import { Form, Col, Card } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import { useParams, Link } from "react-router-dom";
import { GlobalSet } from '../../global/GlobalProvider';
import axios from "axios";
import swal from "sweetalert";
import loading_img from "../../../../images/profile/loading.gif";

const Checkout = () => {
   const { titel } = useParams();
   const [loading, setLoading] = useState(true);
   const [deposit, setDeposit] = useState([]);
   const { BaseUrl } = GlobalSet();

    useEffect(()=>{
        
      const data = {
        key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        titel:titel,
        }
        axios.post('/view-page',data)
        .then(response=>{
           setDeposit(response.data.data)
           setLoading(false);
         })
     .catch(error=>{
            
           console.log(error)
       })

           
   },[titel]);


    if(loading)
    {
        return <div className="d-flex justify-content-center" ><img src={loading_img} alt="" className="me-3 rounded" width={75}/></div> 
    }
   else
    {
   return (


<>
   <span style={{  marginTop: '-36px' }} className="d-xl-block">
      <div className="row">
      <Fragment>
          <Col xl="12" className="p-0" >
            <Card>


              <div className="form-inline">
                <div className="row">

                   <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12" key={deposit.titel}>

                    <div className="card project-boxed">

                        <Card.Header>
                          <Card.Title>{ deposit.titel } </Card.Title>
                        </Card.Header>

                      <div className="card-header align-items-start">
                          <div className="text-black"
                            dangerouslySetInnerHTML={{ __html: deposit.Discription }}
                          />
                      </div>
        
                    </div>
                  </div>
              </div>
              </div>
            </Card>
          </Col>
      </Fragment>

      </div>
    </span>
    </>













   )};
};

export default Checkout;
