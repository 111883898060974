import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import loading_img from "../../../images/profile/loading.gif";
import { GlobalSet } from '../global/GlobalProvider';
import Slider from "react-slick";
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'
import swal from "sweetalert";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
const Home = () => {
    const { changeBackground } = useContext(ThemeContext);
    useEffect(() => {
        changeBackground({ value: "dark", label: "Dark" });
    }, []);

   const { BaseUrl } = GlobalSet();
   const [salider, setSalider] = useState([]); 
   const [homeCasino, setCasino] = useState([]);
   const [homeTopSlider, TopSlider] = useState([]);
   const [homeitem, setHomeItem] = useState([]);   
   const { t } = useTranslation();
/*.....product code........*/
  const [loading, setLoading] = useState(true);
  const data = {
       key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
       }
  const APiResponse = () => {
      axios.post('/home-data',data)
      .then(response=>{
         setSalider(response.data.slider)
         setCasino(response.data.home_casino)
         TopSlider(response.data.top_slider)
         setHomeItem(response.data.home_items)
         setLoading(false); 
       })
   .catch(error=>{
         console.log(error)
     });
   }
    useEffect(()=>{
   const interval = setInterval(
      (function callApi() {
        APiResponse();
        return callApi;
      })(),
      70000000
    );
    return () => clearInterval(interval);
    },[]);


    
//mobile odds option
  const casino_game_s = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 100,
    slidesToShow: 10,
    slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1560,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 6,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 5,
                },
            },  
            {
                breakpoint: 640,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 4,
                },
            },
        ],
  };


  var casino_part = "";
  if(loading)
  {

     return <div className="d-flex justify-content-center align-items-center vh-100"><img src={loading_img} alt="" className="me-3 rounded" width={250} /></div>    
  }
  else
  {
  casino_part = homeCasino.map((ele)=>{
    return (
      <div  className="col-xl-1 col-xxl-2 col-lg-2 col-md-3 col-sm-4 col-4 int-col p-1">  
            <div key={ele.id}>
              <Link to={"/casino-game-get/"+ele.id+"/"+ele.slug} className="text-black user-name">

                  <div className="img-bx">
                      <img src={(ele.image)} alt="" className=" me-3 card-list-img w-100" width="130" />
                  </div>  
               </Link>
            </div>  
        </div>     
      )
     });
  }


return(
     <>
      <span style={{  marginTop: '-36px' }} className="d-xl-block">
  <div className="row">
            <Fragment>
              <Col xl="9" className="p-0" >
                <Carousel className="p-0">
                  {salider.map((carousel, i) => (
                    <Carousel.Item key={i}>
                      <img
                        src={BaseUrl+(carousel.img_link)}
                        className='d-block w-100'
                        alt={`Slide ${i + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel> 
               <div className="App">

                  <div className="table-responsive">
                        <table
                          className="display w-100 dataTable "
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                           style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}
                        >

                          <tbody>
                            <tr className="odd" role="row">
                              <td> <Link to={"/home-game/slots"} className="text-black">{t('All Game')}</Link></td>
                              <td> <Link to={"/home-game/live-casino"} className="text-black">{t('Live Casino')}</Link></td>
                              <td> <Link to={"/home-game/slots"} className="text-black">{t('Table Games')}</Link></td>
                              <td> <Link to={"/home-game/jackpot-slots"} className="text-black">{t('Slots')}</Link></td>
                              <td> <Link to={"/home-game/poker"} className="text-black">{t('Poker')}</Link></td>
                              <td> <Link to={"/home-game/jackpot-slots"} className="text-black">{t('Table Games')}</Link></td>
                              <td> <Link to={"/home-game/slots"} className="text-black">{t('Provider')}</Link></td>
                              <td> <Link to={"/home-game/all-game"} className="text-black">{t('Popular')}</Link></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                </div>
                  <div className="col-xl-12">
                    <div className="card mb-2">
                      <div className="card-body pt-1 pb-1">
                        <Slider className="front-view-slider owl-carousel owl-carousel owl-loaded owl-drag owl-dot" {...casino_game_s}>        
                        {homeTopSlider.map((ele)=>(
                          <div  className="p-1">  
                                <div key={ele.id}>
                                  <Link to={"/casino-game-get/"+ele.id+"/"+ele.slug} className="text-black user-name">

                                      <div className="img-bx">
                                          <img src={(ele.image)} alt="" className=" me-3 card-list-img w-100" width="80" />
                                      </div>  
                                   </Link>
                                </div>  
                            </div>  
                          ))}
                        </Slider>
                      </div>  
                    </div>  
                  </div>
              </Col>

              <Col xl="3" className="pl-1" >
                <div className="row">
                 {homeitem.map((ele)=>(
                    <div  className="col-xl-4 col-xxl-4 col-lg-4 col-md-4 col-sm-6 col-6 int-col p-1">  
                          <div key={ele.id}>
                            <Link to={ele.link} className="text-black user-name">

                                <div className="img-bx">
                                    <img src={BaseUrl+(ele.img_link)} alt="" className=" me-3 card-list-img w-100" width="130" />
                                </div>  
                             </Link>
                          </div>  
                      </div> 
                  ))}
                </div>
              </Col>

              <Col xl="12" className="p-0" >
                <Card>
                  <Card.Body className="p-2" >
                    <div className="form-inline">
                      <div className="row">
                        {casino_part}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

          </Fragment>
        </div> 
      </span>
    </>
  )
}
export default Home;