import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import 'react-phone-number-input/style.css';
import Select from "react-select";
import PhoneInput from 'react-phone-number-input';
import options from "./country";
import logo from "../../images/logo-full.png";
import axios from "axios";

function Register() {
    const location = useLocation();
    const history = useHistory();
    const urlSearchParams = new URLSearchParams(location.search);
    const refValue = urlSearchParams.get('ref');

    const [storRef, setStorRef] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [value, setValue] = useState('');
    const [registerInput, setRegister] = useState({
        name: '',
        username: '',
        email: '',
        password: '',
        con_password: '',
        promocode: '', // Set to an empty string initially
        error_list: [],
    });
     const direct_ref = localStorage.getItem('ref');

    // Save ref value to localStorage if it exists in the URL
    useEffect(() => {
        if (refValue) {
            setStorRef(refValue);
            setRegister(prevState => ({
                ...prevState,
                promocode: refValue // Update promocode in the form
            }));
        } else {
            const storedRef = localStorage.getItem('ref');
            if (storedRef) {
                setStorRef(storedRef);
                setRegister(prevState => ({
                    ...prevState,
                    promocode: storedRef // Update promocode in the form
                }));
            }
        }
    }, [refValue]);

    // Load data if ref value exists
    useEffect(() => {
        const loadDataRe = async () => {
            if (!storRef) return;

            const data = {
                promocode: storRef,
                key_s: "eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9",
            };

            try {
                const response = await axios.post(`/check-promocode`, data);
                if (response.data.status === 'success') {
                    localStorage.setItem('ref', storRef);
                    setRegister(prevState => ({
                        ...prevState,
                        promocode: storRef // Ensure promocode is updated in the form
                    }));
                    //swal("Success", response.data.message, "success");
                } else if (response.data.status === 'fail') {
                    swal("Warning", response.data.message, "warning");
                } else {
                    setRegister(prevState => ({
                        ...prevState,
                        error_list: response.data.validation_error
                    }));
                }
            } catch (error) {
                console.error('Error during promocode validation:', error);
                swal("Error", "An error occurred while validating the promocode", "error");
            }
        };

        loadDataRe();
    }, [storRef]);

    const handleInput = (e) => {
        e.persist();
        setRegister(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    const registerSubmit = async (e) => {
        e.preventDefault();

        const data = {
            name: registerInput.name,
            username: registerInput.username,
            country: selectedOption ? selectedOption.label : '',
            mobile_No: value,
            email: registerInput.email,
            password: registerInput.password,
            con_password: registerInput.con_password,
            promocode: registerInput.promocode || storRef, // Use promocode from input or storRef
            key_s: "eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9",
        };

        try {
            const response = await axios.post(`/apps-registration`, data);
            if (response.data.status === 'success') {
                localStorage.setItem('auth_token', response.data.accessToken);
                localStorage.removeItem('ref');
                swal("Success", response.data.message, "success");
                history.push('/dashboard');
            } else if (response.data.status === 'fail') {
                swal("Warning", response.data.message, "warning");
            } else {
                setRegister(prevState => ({
                    ...prevState,
                    error_list: response.data.validation_error
                }));
            }
        } catch (error) {
            console.error('Error during registration:', error);
            swal("Error", "An error occurred during registration", "error");
        }
    };

    return (
        <div>
            <div className="row justify-content-center h-100 align-items-center mb-5">
                <div className="col-md-10">
                    <div className="authincation-content">
                        <div className="row no-gutters">
                            <div className="col-xl-12">
                                <div className="auth-form">
                                    <div className="text-center mb-3">
                                        <Link to="/">
                                            <img src={logo} alt="" />
                                        </Link>
                                    </div>
                                    <h4 className="text-center">Sign up your account</h4>
                                    <hr />
                                    <form onSubmit={registerSubmit}>
                                        <div className="form-group mb-3 mt-4">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="form-group mb-3 col-md-6">
                                                        <label className="mb-1 text-black">
                                                            <strong>Name</strong>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Name"
                                                            name="name"
                                                            onChange={handleInput}
                                                            value={registerInput.name}
                                                        />
                                                        <span className="text-primary">{registerInput.error_list.name}</span>
                                                    </div>

                                                    <div className="form-group mb-3 col-md-6">
                                                        <label className="mb-1 text-black">
                                                            <strong>Username</strong>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Username"
                                                            name="username"
                                                            onChange={handleInput}
                                                            value={registerInput.username}
                                                        />
                                                        <span className="text-primary">{registerInput.error_list.username}</span>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label className="mb-1 text-black">
                                                        <strong>Country / Region of residence</strong>
                                                    </label>
                                                    <Select
                                                        value={selectedOption}
                                                        onChange={setSelectedOption}
                                                        options={options}
                                                        style={{
                                                            lineHeight: '40px',
                                                            color: '#7e7e7e',
                                                            paddingLeft: '15px',
                                                        }}
                                                    />
                                                    <span className="text-primary">{registerInput.error_list.country}</span>
                                                </div>

                                                <strong className="text-black">Phone Number</strong>
                                                <div className="form-group form-control mb-3">
                                                    <PhoneInput
                                                        international
                                                        countryCallingCodeEditable={false}
                                                        defaultCountry={selectedOption ? selectedOption.value : ''}
                                                        value={value}
                                                        onChange={setValue}
                                                        style={{
                                                            padding: '4px'
                                                        }} />
                                                </div>
                                                <span className="text-primary">{registerInput.error_list.mobile_No}</span>

                                                <div className="form-group mb-3">
                                                    <label className="mb-1 text-black">
                                                        <strong>Email</strong>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email"
                                                        name="email"
                                                        onChange={handleInput}
                                                        value={registerInput.email}
                                                    />
                                                    <span className="text-primary">{registerInput.error_list.email}</span>
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label className="mb-1 text-black">
                                                        <strong>Promocode (Optional)</strong>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Promocode"
                                                        name="promocode"
                                                        onChange={handleInput}
                                                        value={registerInput.promocode}
                                                        {...(direct_ref === null ? {} : { readOnly: direct_ref !== false })}


                                                    />
                                                    <span className="text-primary">{registerInput.error_list.promocode}</span>
                                                </div>

                                                <strong className="text-black">Password</strong>
                                                <div className="input-group transparent-append mb-2">
                                                    <input
                                                        type={`${showPassword ? "text" : "password"}`}
                                                        className="form-control"
                                                        placeholder="Password"
                                                        name="password"
                                                        onChange={handleInput}
                                                        value={registerInput.password}
                                                    />
                                                    <span className="text-primary">{registerInput.error_list.password}</span>
                                                    <div
                                                        className="input-group-text"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword === false ? (
                                                            <i className="fa fa-eye-slash" />
                                                        ) : (
                                                            <i className="fa fa-eye" />
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label className="mb-1 text-black">
                                                        <strong>Confirm password</strong>
                                                    </label>
                                                    <input
                                                        type={`${showPassword ? "text" : "password"}`}
                                                        className="form-control"
                                                        placeholder="Confirm password"
                                                        name="con_password"
                                                        onChange={handleInput}
                                                        value={registerInput.con_password}
                                                    />
                                                    <span className="text-primary">{registerInput.error_list.con_password}</span>
                                                </div>

                                                <div className="text-center mt-4">
                                                    <button type="submit" className="btn btn-primary btn-block text-black">
                                                        Sign me up
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="new-account mt-3">
                                            <p className="text-black">
                                                Go to Home?{" "}
                                                <Link className="text-primary" to="/">
                                                    Home
                                                </Link>
                                            </p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
